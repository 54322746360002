import { ReactNode, createContext, useState } from "react";
import { ToastMessageStateDefault } from "./ToastMessage.constants";
import { ToastMessage, ToastMessageState } from "./ToastMessage.types";

export const ToastMessageContext = createContext<ToastMessageState>(
  ToastMessageStateDefault,
);

export const ToastMessageProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  return (
    <ToastMessageContext.Provider
      value={{
        messages,
        setMessages,
      }}
    >
      {children}
    </ToastMessageContext.Provider>
  );
};
