import "bootstrap/dist/css/bootstrap.min.css";

import { Global } from "@emotion/react";
import LftLoading from "@lift/design-system-react-web/dist/components/LftLoading";
import { LftProvider } from "@lift/design-system-react-web/dist/contexts";
import { api, apm, auth, brand, env, host } from "src/environment";
import { AuthProvider } from "src/libs/auth";
import { initializeApm } from "./libs/apm/apm";

import { FullLayout } from "src/components/FullLayout/FullLayout.component";
import { Navigation } from "src/features/Navigation/Navigation.component";
import Loading from "./components/Loading/Loading.component";
import { LoadingProvider } from "./contexts/LoadingContext/Loading.provider";
import { ServiceRequestErrorProvider } from "./contexts/ServiceRequestContext/ServiceRequestError.provider";
import { ToastMessageProvider } from "./contexts/ToastMessageContext/ToastMessage.provider";
import { globalStyles } from "./global.styles";

initializeApm({ api, apm, env, brand });

const App = () => {
  return (
    <LftProvider brand={brand} template={"default"}>
      <LoadingProvider>
        <ToastMessageProvider>
          <ServiceRequestErrorProvider>
            <AuthProvider
              authConfig={auth}
              host={host}
              onLoading={
                <FullLayout>
                  <LftLoading />
                </FullLayout>
              }
            >
              {/*Componente do @emotion para adicionar estilos de forma global na aplicação, usa como base as definições do: 'globa.styles.ts' */}
              <Global styles={globalStyles} />
              <Navigation />
              <Loading />
            </AuthProvider>
          </ServiceRequestErrorProvider>
        </ToastMessageProvider>
      </LoadingProvider>
    </LftProvider>
  );
};

export default App;
